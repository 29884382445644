
import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Cookies from 'quasar/src/plugins/Cookies.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Cookies
  }
}